import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { Button, TextField, Box, Typography } from "@mui/material";

function SignIn() {
  const navigate = useNavigate();

  const handleSignIn = (event) => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    signInWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        navigate("/dashboard"); // Redirect to dashboard after sign-in
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
      });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate("/dashboard"); // Redirect to dashboard after Google sign-in
      })
      .catch((error) => {
        console.error("Error signing in with Google: ", error);
      });
  };

  return (
    <Box sx={{ maxWidth: 300, mx: "auto", mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Sign In
      </Typography>
      <form onSubmit={handleSignIn}>
        <TextField
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign In
        </Button>
      </form>
      <Button
        onClick={signInWithGoogle}
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Sign In with Google
      </Button>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Link to="/forgot-password">Forgot Password?</Link>
      </Box>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </Box>
    </Box>
  );
}

export default SignIn;
