import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Box,
  Modal,
  Card,
  CardContent,
} from "@mui/material";

function ApplicationList() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const fetchApplications = async () => {
    setLoading(true);
    try {
      // First fetch the jobs posted by the current employer
      const jobsQuery = query(
        collection(db, "jobs"),
        where("employerId", "==", auth.currentUser.uid)
      );
      const jobsSnapshot = await getDocs(jobsQuery);
      const jobIds = jobsSnapshot.docs.map((doc) => doc.id); // Collect job IDs

      // Now fetch the applications for those jobs
      const applicationsArray = [];
      for (const jobId of jobIds) {
        const applicationsQuery = query(
          collection(db, "applications"),
          where("jobId", "==", jobId)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        applicationsArray.push(
          ...applicationsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }
      setApplications(applicationsArray);
    } catch (error) {
      console.error("Error fetching applications: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, []); // Corrected useEffect hook

  const handleListItemClick = (app) => {
    setSelectedApplication(app);
  };

  const handleCloseModal = () => {
    setSelectedApplication(null);
  };
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 2 }}>
      <List>
        {applications.map((app) => (
          <ListItem
            key={app.id}
            divider
            button
            onClick={() => handleListItemClick(app)}
          >
            <ListItemText
              primary={app.candidateName}
              secondary={`Applied for ${app.jobTitle}`}
            />
          </ListItem>
        ))}
      </List>
      {selectedApplication && (
        <Modal
          open={!!selectedApplication}
          onClose={handleCloseModal}
          aria-labelledby="application-details"
        >
          <Card
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {selectedApplication.candidateName}
              </Typography>
              <Typography variant="body1">
                Applied for: {selectedApplication.jobTitle}
              </Typography>
              <Typography variant="body1">
                Email: {selectedApplication.candidateEmail}
              </Typography>
              <Typography variant="body1">
                Cover Letter: {selectedApplication.coverLetter}
              </Typography>
              {selectedApplication.resumeUrl && (
                <Typography variant="body1">
                  <a
                    href={selectedApplication.resumeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Resume
                  </a>
                </Typography>
              )}
            </CardContent>
          </Card>
        </Modal>
      )}
    </Box>
  );
}

export default ApplicationList;
