import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import JobDetails from "./components/JobDetails";
import JobListingsGrid from "./components/JobListingsGrid"; // Import the new component
import SearchLab from "./components/SearchLabs";
import { auth } from "./firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

function JobListingsGridWrapper() {
  const location = useLocation();
  const categories = location.state?.categories || [];
  return <JobListingsGrid categories={categories} />;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={!isAuthenticated ? <SignIn /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/signup"
          element={!isAuthenticated ? <SignUp /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/jobs"
          element={<JobListingsGrid />} // Public route for job listings
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/dashboard" : "/jobs"} />}
        />

        <Route
          path="/labs"
          element={<JobListingsGridWrapper />} // Use the wrapper component here
        />
        <Route
          path="/job/:jobId" // <-- Add this route
          element={<JobDetails />} // JobDetails will receive the jobId as a prop
        />
        <Route
          path="/search"
          element={<SearchLab />} // Route for SearchLab component
        />
      </Routes>
    </Router>
  );
}

export default App;
