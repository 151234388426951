import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import ApplyForm from "./ApplyForm";
import {
  Container,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EventIcon from "@mui/icons-material/Event";
import "./JobDetails.css";

// Component to display loading state
const Loading = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress />
  </Box>
);

// Component to display error state
const Error = () => (
  <Typography variant="h6" textAlign="center">
    Job not found
  </Typography>
);

// Component to display job details
const JobInfo = ({ job }) => (
  <Box>
    <Typography
      variant="h3"
      gutterBottom
      component="div"
      sx={{ fontWeight: "bold", color: "#333" }}
    >
      {job.Title}
    </Typography>

    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#1976D2",
              }}
            >
              <BusinessCenterIcon sx={{ mr: 1 }} />
              Department: {job.Department}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#1976D2",
              }}
            >
              <EventIcon sx={{ mr: 1 }} />
              Deadline: {job.Deadline}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    <Typography
      variant="body1"
      sx={{ mt: 3, color: "#424242", lineHeight: "1.6", fontSize: "1.05rem" }}
    >
      <Box pb={2}>
        <strong>Position Requirements:</strong> {job["Position Requirements"]}
      </Box>
      <Box pb={2}>
        <strong>Position Duties:</strong> {job["Position Duties"]}
      </Box>
      <Box pb={2}>
        <strong>Website:</strong>
        <a
          href={job.Website}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1976D2", textDecoration: "underline" }}
        >
          {job.Website}
        </a>
      </Box>
      <Box pb={2}>
        <strong>Salary:</strong> {job.Salary}
      </Box>
      <Box pb={2}>
        <strong>Start Date:</strong> {job["Start Date"]}
      </Box>
      <Box pb={2}>
        {" "}
        {/* Padding bottom added to create space after the paragraph */}
        <strong>Mentor:</strong> {job.Mentor}
      </Box>
      <Box pb={2}>
        <strong>Posted:</strong> {job.Posted}
      </Box>
    </Typography>
  </Box>
);

function JobDetails() {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const docRef = doc(db, "jobs", jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJob(docSnap.data());
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchJob();
  }, [jobId]);

  const handleBackToListings = () => {
    navigate("/jobs"); // Adjust the route as per your routing setup
  };

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={6} sx={{ p: 3 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackToListings}
            >
              Back to Listings
            </Button>
          </Grid>
          <Grid item>
            <ApplyForm jobId={job.id} jobTitle={job.title} />
          </Grid>
        </Grid>
        {job && <JobInfo job={job} />}
      </Paper>
    </Container>
  );
}

export default JobDetails;
