import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./SearchLab.css";

const SearchLab = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getFirestore();
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesArray = querySnapshot.docs.map(
          (doc) => doc.data().name
        );
        setCategories(categoriesArray);
      } catch (error) {
        console.error("There was an error fetching the categories!", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = async () => {
    const db = getFirestore();
    try {
      const labsRef = collection(db, "labs");
      let categoriesArray = selectedCategories;
      if (typeof selectedCategories === "string") {
        categoriesArray = selectedCategories.split(", "); // converting string to array if necessary
      }
      const querySnapshot = await getDocs(
        query(
          labsRef,
          where("categories", "array-contains-any", categoriesArray)
        )
      );
      const labsArray = querySnapshot.docs.map((doc) => doc.data());
      navigate("/labs", { state: { categories: categoriesArray } }); // passing array to the next component
    } catch (error) {
      console.error("There was an error fetching the labs!", error);
    }
  };
  return (
    <div className="search-container">
      <Typography variant="h4" gutterBottom>
        Search For a Lab
      </Typography>
      <div className="search-card">
        <Autocomplete
          multiple
          options={categories.slice().sort((a, b) => a.localeCompare(b))}
          value={selectedCategories}
          onChange={(event, newValue) => setSelectedCategories(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Research Fields"
              placeholder="Select categories"
            />
          )}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchLab;
