import React from "react";
import JobForm from "../components/JobForm";
import JobList from "../components/JobList";
import ApplicationList from "../components/ApplicationList";
import { Container, Grid, Typography, Box, Button, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import { signOut } from "firebase/auth";

function Dashboard() {
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out: ", error);
      });
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" gutterBottom component="h1">
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3, mb: 3 }}>
              <JobForm />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, mb: 3, height: "100%" }}>
              <Typography variant="h5" gutterBottom>
                Job Listings
              </Typography>
              <JobList />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3, height: "100%" }}>
              <Typography variant="h5" gutterBottom>
                Applications
              </Typography>
              <ApplicationList />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant="contained"
        color="error"
        onClick={handleSignOut}
        sx={{ mt: 3 }}
      >
        Sign Out
      </Button>
    </Container>
  );
}

export default Dashboard;
