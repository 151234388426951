import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Paper } from "@mui/material";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Autocomplete from "@mui/material/Autocomplete";
import "./SearchBar.css"; // Ensure this path is correct

const SearchBar = ({ onSearch }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesArray = querySnapshot.docs.map(
          (doc) => doc.data().name
        );
        setCategories(categoriesArray);
      } catch (error) {
        console.error("Error fetching the categories!", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = () => {
    onSearch(selectedCategories);
  };

  return (
    <Paper className="search-bar" elevation={3}>
      <Typography variant="h6" gutterBottom>
        Filter Jobs
      </Typography>
      <Autocomplete
        multiple
        options={categories.slice().sort((a, b) => a.localeCompare(b))}
        getOptionLabel={(option) => option} // Ensure this is correct for your data
        value={selectedCategories}
        onChange={(event, newValue) => setSelectedCategories(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Research Fields"
            placeholder="Select categories"
            fullWidth
          />
        )}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          fullWidth
        >
          Search
        </Button>
      </Box>
    </Paper>
  );
};

export default SearchBar;
