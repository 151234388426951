import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

function JobForm() {
  const [title, setTitle] = useState("");
  const [researchArea, setResearchArea] = useState("");
  const [department, setDepartment] = useState("");
  const [mentor, setMentor] = useState("");
  const [applicationDeadline, setApplicationDeadline] = useState("");
  const [positionRequirements, setPositionRequirements] = useState("");
  const [positionDuties, setPositionDuties] = useState("");
  const [website, setWebsite] = useState("");
  const [salary, setSalary] = useState("");
  const [startDate, setStartDate] = useState("");
  const [contactName, setContactName] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      // Add the job details to Firestore
      await addDoc(collection(db, "jobs"), {
        title,
        researchArea,
        department,
        mentor,
        applicationDeadline,
        positionRequirements,
        positionDuties,
        website,
        salary,
        startDate,
        contactName,
      });

      // Reset form and state
      setTitle("");
      setResearchArea("");
      setDepartment("");
      setMentor("");
      setApplicationDeadline("");
      setPositionRequirements("");
      setPositionDuties("");
      setWebsite("");
      setSalary("");
      setStartDate("");
      setContactName("");
      alert("Job posted successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to post the job.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        "& .MuiButton-root": { m: 1 },
      }}
    >
      <Typography variant="h6">Post a New Job</Typography>
      {/* Form fields updated as per the requirement */}
      <TextField
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <TextField
        label="Research Area"
        variant="outlined"
        value={researchArea}
        onChange={(e) => setResearchArea(e.target.value)}
        required
      />
      <TextField
        label="Department"
        variant="outlined"
        value={department}
        onChange={(e) => setDepartment(e.target.value)}
        required
      />
      <TextField
        label="Mentor"
        variant="outlined"
        value={mentor}
        onChange={(e) => setMentor(e.target.value)}
        required
      />
      <TextField
        label="Application Deadline"
        variant="outlined"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={applicationDeadline}
        onChange={(e) => setApplicationDeadline(e.target.value)}
        required
      />
      <TextField
        label="Position Requirements"
        variant="outlined"
        multiline
        rows={4}
        value={positionRequirements}
        onChange={(e) => setPositionRequirements(e.target.value)}
        required
      />
      <TextField
        label="Position Duties"
        variant="outlined"
        multiline
        rows={4}
        value={positionDuties}
        onChange={(e) => setPositionDuties(e.target.value)}
        required
      />
      <TextField
        label="Website"
        variant="outlined"
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        required
      />
      <TextField
        label="Salary"
        variant="outlined"
        value={salary}
        onChange={(e) => setSalary(e.target.value)}
        required
      />
      <TextField
        label="Start Date"
        variant="outlined"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        required
      />
      <TextField
        label="Contact Name"
        variant="outlined"
        value={contactName}
        onChange={(e) => setContactName(e.target.value)}
        required
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={uploading}
        sx={{ mt: 2 }}
      >
        {uploading ? <CircularProgress size={24} /> : "Post Job"}
      </Button>
    </Box>
  );
}

export default JobForm;
