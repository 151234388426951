// JobListingsGrid.js
import React, { useEffect, useState } from "react";
import {
  Grid as MuiGrid,
  CircularProgress,
  Box,
  Typography,
  Container,
  Drawer,
} from "@mui/material";
import JobInfo from "./JobInfo";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar"; // Import SearchBar
import logo from "./the_logo.png"; // Import the logo
import "./JobListingsGrid.css";
import { makeStyles } from "@mui/styles";

function JobListingsGrid() {
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs(categories);
  }, [categories]);

  const fetchJobs = async (categories) => {
    console.log(categories);
    try {
      const jobsRef = collection(db, "jobs");
      let jobsQuery = jobsRef;
      if (categories && categories.length > 0) {
        jobsQuery = query(
          jobsRef,
          where("categories", "array-contains-any", categories)
        );
      }
      const querySnapshot = await getDocs(jobsQuery);
      const jobsArray = querySnapshot.docs.map((doc) => ({
        jobId: doc.jobId,
        ...doc.data(),
      }));
      setJobsData(jobsArray);
      console.log(jobsArray);
    } catch (error) {
      console.error("There was an error fetching jobs data:", error);
      setError(error.message);
    }
    setLoading(false);
  };

  const handleJobClick = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const handleSearch = (selectedCategories) => {
    setCategories(selectedCategories);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" textAlign="center" color="error">
        Error loading jobs: {error}
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" className="job-listings-container">
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "16px",
          zIndex: -1,
        }}
      ></Box>

      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        style={{ position: "absolute", top: 0, left: 0, padding: "16px" }}
      ></Box>
      <div className="custom-sidebar">
        <SearchBar onSearch={handleSearch} />
      </div>

      <MuiGrid container spacing={4}>
        {jobsData.map((job, index) => (
          <MuiGrid item xs={12} sm={6} md={4} key={index} className="grid-item">
            <JobInfo {...job} onClick={() => handleJobClick(job.jobId)} />
          </MuiGrid>
        ))}
      </MuiGrid>
    </Container>
  );
}

export default JobListingsGrid;
