import { Card, CardContent, Typography } from "@mui/material";
import ScienceIcon from "@mui/icons-material/Science";
import EventIcon from "@mui/icons-material/Event";

function JobInfo(props) {
  const {
    Title,
    Department,
    Deadline,
    onClick,
    jobId,
    "Research Area": ResearchArea,
  } = props;

  const handleClick = () => {
    onClick(jobId);
  };

  return (
    <Card
      elevation={3}
      onClick={handleClick}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        overflow: "hidden",
        cursor: "pointer",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.12)",
        },
        backgroundColor: "#fff", // white background
        borderColor: "rgba(0, 0, 0, 0.23)", // gray border
        borderWidth: "1px",
        borderStyle: "solid",
        color: "black", // blue text
      }}
    >
      <CardContent
        sx={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            marginBottom: "8px",
            color: "inherit", // Inherits blue text color
          }}
        >
          {Title} - {Department}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
            color: "inherit",
          }}
        >
          <ScienceIcon sx={{ marginRight: 1, color: "inherit" }} />{" "}
          {ResearchArea}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
            color: "inherit",
          }}
        >
          <EventIcon sx={{ marginRight: 1, color: "inherit" }} /> {Deadline}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default JobInfo;
