import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { Button, TextField, Box, Typography } from "@mui/material";

function SignUp() {
  const navigate = useNavigate();

  const handleSignUp = (event) => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    createUserWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        navigate("/dashboard"); // Redirect to dashboard after successful sign-up
      })
      .catch((error) => {
        console.error("Error signing up: ", error);
      });
  };

  const signUpWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate("/dashboard"); // Redirect to dashboard after successful Google sign-up
      })
      .catch((error) => {
        console.error("Error signing up with Google: ", error);
      });
  };

  return (
    <Box sx={{ maxWidth: 300, mx: "auto", mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSignUp}>
        <TextField
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign Up
        </Button>
      </form>
      <Button
        onClick={signUpWithGoogle}
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Sign Up with Google
      </Button>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        Already have an account? <Link to="/login">Log In</Link>
      </Box>
    </Box>
  );
}

export default SignUp;
