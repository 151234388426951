import React, { useState } from "react";
import {
  Modal,
  Button,
  TextField,
  Box,
  Typography,
  Snackbar,
} from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import { db, storage } from "../firebase/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

function ApplyForm({ jobId, jobTitle }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload resume to Firebase Storage
    const resumeRef = ref(storage, `resumes/${resume.name}`);
    try {
      const resumeSnapshot = await uploadBytes(resumeRef, resume);
      const resumeUrl = await getDownloadURL(resumeSnapshot.ref);

      // Save application details to Firestore
      await addDoc(collection(db, "applications"), {
        jobId,
        jobTitle,
        candidateEmail: email,
        resumeUrl,
        coverLetter,
        timestamp: new Date(),
      });

      // Close the modal and reset the form
      handleClose();
      setEmail("");
      setResume(null);
      setCoverLetter("");

      // Trigger an email notification to the employer
      // This can be done using Firebase Functions or an external API
      // sendEmailNotificationToEmployer(jobId, jobTitle, email); // This is a placeholder function

      setSnackbarMessage("Application submitted successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error submitting application: ", error);
      setSnackbarMessage("Failed to submit application.");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{
          fontSize: "1.2rem", // Larger font size
          padding: "10px 20px", // More padding for larger button size
          // Add any other styles you want to adjust
        }}
      >
        Apply
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="apply-form-title"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 500,
            width: "100%",
          }}
        >
          <Typography
            id="apply-form-title"
            variant="h6"
            component="h2"
            marginBottom={2}
          >
            Apply for {jobTitle}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              required
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              component="label"
              fullWidth
              margin="normal"
            >
              Upload Resume
              <input
                type="file"
                hidden
                required
                accept=".pdf"
                onChange={(e) => setResume(e.target.files[0])}
              />
            </Button>
            <TextField
              label="Why you are a good fit"
              multiline
              rows={4}
              fullWidth
              required
              margin="normal"
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit Application
            </Button>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default ApplyForm;
