import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { auth } from "../firebase/firebaseConfig";
import { query, where } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

function JobList() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const goToJobDetails = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const jobsQuery = query(
        collection(db, "jobs"),
        where("employerId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(jobsQuery);
      const jobsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setJobs(jobsArray);
    } catch (error) {
      console.error("Error fetching jobs: ", error);
    }
    setLoading(false);
  };

  const handleDelete = async (jobId) => {
    if (window.confirm("Are you sure you want to delete this job listing?")) {
      try {
        await deleteDoc(doc(db, "jobs", jobId));
        fetchJobs(); // Re-fetch jobs to update the list
      } catch (error) {
        console.error("Error deleting job: ", error);
        alert("Failed to delete the job.");
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 2 }}>
      <List>
        {jobs.map((job) => (
          <ListItem
            key={job.id}
            divider
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(job.id)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={job.title}
              secondary={`${job.location} (${job.category})`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default JobList;
